import { BackgroundContainer, BottomContainer, ImageContainer, TopContainer, ContentContainer } from "./style";

export const Home = () => {
    return(
        <ImageContainer>
            <BackgroundContainer>
                <ContentContainer>
                <TopContainer>
                <h1>Venha <strong>aprender ou compartilhar </strong>conhecimentos no Arduino Day 2023 da rede Fab Lab Livre SP</h1>
                </TopContainer>
                
                <BottomContainer>
                <h2><strong>Dia 25/03 - 10h às 17h</strong></h2>
                <h2>Local: Largo do Rosário, 20 - Penha de França, São Paulo - SP, 03634-020</h2>
                </BottomContainer>
                </ContentContainer>
            </BackgroundContainer>
        </ImageContainer>
    )
}