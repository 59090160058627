import { StoryContainer } from "./style";

export const Story = () => {
    return (
        <StoryContainer>
            <h1 id="historia"> História </h1>
            <p>O arduino é uma placa de prototipagem de código aberto, sendo seu principal intuito ser um equipamento que permite qualquer pessoa do mundo acessar tecnologias avançadas com baixo custo. </p>
            <p>O arduino surgiu na Itália, em 2005, e até hoje é uma das principais ferramentas de prototipação do mundo. Essa placa permite que você utilize várias peças acopladas a ele, e assim, é possível desenvolver projetos interativos e avançados de uma forma simples e eficaz. Mas o arduino não é composto somente pelo Hardware. Ele também possui um ambiente gráfico para desenvolver seu projeto utilizando as linguagens C e C++. </p>
	        <p>Essa placa sendo uma ferramenta poderosa para a prototipação de projetos e consequentemente para o empreendedorismo, é utilizado na rede Fab Lab Livre SP nos cursos gratuitos de arduino, desde 2016. Visando métodos pedagógicos inovadores, inclusão tecnológica e acessibilidade. </p>
	        <p>Caso queira saber mais sobre a nossa rede de laboratórios ou sobre os cursos gratuitos oferecidos pela mesma, acesse nosso <a href="https://www.fablablivresp.prefeitura.sp.gov.br/">site</a>.</p>
        </StoryContainer>
    )
}