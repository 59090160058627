import { FooterContainer, LogoContainer, ContentContainer } from "./style";
import logoOrganizer from "../../img/logo-horizontal.png"
import logoOrganizer2 from "../../img/logo-horizontal2.png"
import logoOrganizer3 from "../../img/logo-horizontal3.png"

export const Footer = () => {
  return (
    <FooterContainer>
      <LogoContainer>
        <img src= { logoOrganizer } alt="Logo FAB LAB" />
        <img src= { logoOrganizer2 } alt="Logo SMIT" />
      </LogoContainer>
      <ContentContainer>
        <p>Secretaria Municipal de Inovação e Tecnologia</p>
        <p>Rua Líbero Badaró, 425 - 27º e 34º andares - Centro </p>
        <p><a href="https://www.fablablivresp.prefeitura.sp.gov.br/">https://www.fablablivresp.prefeitura.sp.gov.br/</a></p>
        <p><a href="mailto:fablab@prefeitura.sp.gov.br">fablab@prefeitura.sp.gov.br</a></p>
      </ContentContainer>
    </FooterContainer>
  )
}