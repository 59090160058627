import { ScheduleContainer } from './style'

export const Schedule = () => {
    return (
        <ScheduleContainer>
            <h1 id="programacao">Programação</h1>
            <ul>
                <li>
                    <span>10h</span>
                    <p><strong>ESPAÇO KIDS:</strong> Robótica com Materiais Recicláveis &#124; Rafael de Souza Oliveira &#124; Local: Sala 2 - 2º andar</p>
                </li>
                <li>
                    <span>11h</span>
                    <p><strong>EDUCAÇÃO DO FUTURO:</strong> Teclado musical eletrônico com massinha de modelar &#124; Thayná e Guilherme &#124; Local: Sala de Dança - 2º andar</p>
                </li>
                <li>
                    <span>11h</span>
                    <p><strong>ARTE E SUSTENTABILIDADE</strong>: Aplicação de Vestuários com Arduino Lilypad &#124; Leonardo, Carol, Marcela, Marcix, Willian, Jaya e Carlos &#124; Local: Sala de Ateliê - 3º andar</p>
                </li>
                <li>
                    <span>11h</span>
                    <p><strong>AUTOMAÇÃO:</strong> Alimentador de Pets Automático com Arduino &#124; Gustavo e Wesley &#124; Local: Fab Lab - 3º andar</p>
                </li>
                <li>
                    <span>14h</span>
                    <p><strong>AUTOMAÇÃO:</strong> Sinalizador Anti-Abuso &#124; Stefani e Kaithleen &#124; Local: Fab Lab - 3º andar</p>
                </li>
                <li>
                    <span>14h</span>
                    <p><strong>ESPAÇO KIDS:</strong> Criando e recriando novos territórios educativos com Arduino e Computação Criativa &#124; Valter Garoli, Gislaine Munhoz, Claudia Meirelles e Gisele Mendes &#124; Local: Sala 2 - 2º andar</p>
                </li>
                <li>
                    <span>14h</span>
                    <p><strong>ARTE E SUSTENTABILIDADE:</strong> Programação em Bloco e Servomotor &#124; Prof. Caio Marques &#124; Local: Sala de Ateliê - 3º andar</p>
                </li>
            
                <li>
                    <span>15h</span>
                    <p><strong>EDUCAÇÃO DO FUTURO:</strong> Introdução com LEDs Produção de Brinquedos &#124; Gabriela e Jhonny &#124; Local: Sala de Dança - 2º andar</p>
                </li>
            </ul>
        </ScheduleContainer>
    )
}