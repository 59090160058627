import { ImageContainer, BackgroundContainer, ContentContainer, TopContainer, BottomContainer } from "./style";

export const MiddleBanner = () => {
  return (
    <ImageContainer>
      <BackgroundContainer>
      	<ContentContainer>
					<TopContainer>
						<h1 id="faca-parte">Venha <strong>expor seu projeto</strong> no Arduino Day da rede Fab Lab Livre SP</h1>
					</TopContainer>
					<BottomContainer>
						<p><a href="https://forms.gle/gV2h3KxSeUc3oQji8">Preencha o formulário</a></p>
					</BottomContainer>
      	</ContentContainer>
			</BackgroundContainer>
  	</ImageContainer>
    )
}